import {get} from "@/request";
import {
  IStatisticsPromoterDataParam,
  IStatisticsPromoterDataRes
} from "@/apis/statistics/promoteData/types";

export const dataStatCQueryByPageApi = (data: IStatisticsPromoterDataParam) => get<IStatisticsPromoterDataRes>("/admin/data/stat/c/queryByPage", data)

export const dataStatCInvitationToEnterApi = (params: any) => get("/admin/data/stat/c/invitationToEnter", params)

export const dataStatCVisitsDetailApi = (params: any) => get("admin/data/stat/c/visitsDetail", params)

export const dataStatCGenerateOrdersApi = (params: any) => get("/admin/data/stat/c/generateOrders", params)

export const dataStatCGenerateOrdersDetailApi = (params: any) => get("/admin/data/stat/c/generateOrdersDetail", params)

export const dataStatCRechargeMemberNumberApi = (params: any) => get("/admin/data/stat/c/rechargeMemberNumber", params)

export const dataStatCQueryByExportApi = (params: any) => get("/admin/data/stat/c/queryByExport", params, "blob")
