
/**
 * @name: 推广员管理-推广员信息管理
 * @author: itmobai
 * @date: 2024-01-11 17:32
 * @description： 推广员管理-推广员信息管理
 * @update: 2024-01-11 17:32
 */
import { Vue, Component } from "vue-property-decorator"
import config from "@/config";
import type { ICrudOption } from "@/types/m-ui-crud"
import {deepCopy, exportFile} from "@/utils/common";
import {IStatisticsPromoterData, IStatisticsPromoterDataParam} from "@/apis/statistics/promoteData/types";
import {
  dataStatCGenerateOrdersApi, dataStatCGenerateOrdersDetailApi,
  dataStatCInvitationToEnterApi, dataStatCQueryByExportApi,
  dataStatCQueryByPageApi, dataStatCRechargeMemberNumberApi,
  dataStatCVisitsDetailApi
} from "@/apis/statistics/promoteData";

@Component({})
export default class statisticsPromoteData extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: any = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "推广员",
        prop: "keyWord",
        search: true,
        placeholder: "推广员ID/姓名",
        hide: true
      },
      {
        label: "日期",
        prop: "day",
        align: "center",
        width: 120,
        search: true,
        type: "daterange",
        searchSlot: true
      },
      {
        label: "推广员ID",
        prop: "promoterId",
        width: 180,
        align: "center"
      },
      {
        label: "姓名",
        prop: "promoterName",
        align: "center",
        overHidden: true
      },
      {
        label: "邀请进入【藏地粮市】人数",
        prop: "visitCount",
        align: "center",
        width: 180,
        slot: true
      },
      {
        label: "产生【藏地粮市】订单用户人数",
        prop: "userOrderCount",
        align: "center",
        width: 220
      },
      {
        label: "产生【藏地粮市】订单笔数",
        prop: "orderCount",
        align: "center",
        width: 180,
        slot: true
      },
      {
        label: "产生【藏地粮市】订单金额合计",
        prop: "orderPrice",
        align: "center",
        width: 220
      },
      {
        label: "充值【藏地粮市】PLUS会员人数",
        prop: "memberCount",
        align: "center",
        width: 220,
        slot: true
      }
    ]
  }

  inviteCPeopleNum = 0
  cOrderPeopleNum = 0
  cOrderNum = 0
  cOrderPrice = 0
  cPlusNum = 0

  // 邀请进入藏地粮市人数
  inviteCNumDialog = false
  inviteCNumQuery: any = {
    page: 1,
    limit: 10,
    ymd: '',
    promoterId: ''
  }
  inviteCNumTotal = 0
  inviteCNumSum = 0
  inviteCNumList: any = []
  inviteCNumColumn: any = [
    {
      label: "序号",
      type: "index",
      align: "center",
      width: 50
    },
    {
      label: "用户昵称",
      prop: "userName",
      overHidden: true,
      align: "center",
    },
    {
      label: "用户电话",
      prop: "userPhone",
      align: "center",
    },
    {
      label: "访问次数",
      prop: "visits",
      align: "center",
      slot: true
    },
  ]

  inviteCNumDetailDialog = false
  inviteCNumDetailQuery: any = {
    page: 1,
    limit: 10,
    ymd: '',
    promoterId: '',
    openId: ''
  }
  inviteCNumDetailTotal = 0
  inviteCNumDetailList: any = []
  inviteCNumDetailColumn: any = [
    {
      label: "序号",
      type: "index",
      align: "center"
    },
    {
      label: "访问时间",
      prop: "addTime",
      align: "center",
    },
  ]

  // 产生【藏地粮市】订单笔数
  cOrderNumDialog = false
  cOrderNumQuery: any = {
    page: 1,
    limit: 10,
    promoterId: '',
    ymd: ''
  }
  cOrderNumTotal = 0
  cOrderNumSum = 0
  cOrderNumPriceTotal = 0
  cOrderNumPayPriceTotal = 0
  cOrderNumList: any = []
  cOrderNumColumn: any = [
    {
      label: "序号",
      type: "index",
      align: "center",
      width: 50
    },
    {
      label: "用户昵称",
      prop: "userName",
      overHidden: true,
      align: "center",
    },
    {
      label: "用户电话",
      prop: "userPhone",
      align: "center",
    },
    {
      label: "产生订单笔数",
      prop: "ordersNo",
      align: "center",
      slot: true
    },
  ]

  cOrderNumDetailDialog = false
  cOrderNumDetailQuery: any = {
    page: 1,
    limit: 10
  }
  cOrderNumDetailTotal = 0
  cOrderNumDetailList: any = []
  cOrderNumDetailPriceTotal = 0
  cOrderNumDetailPayPriceTotal = 0
  cOrderNumDetailColumn: any = [
    {
      label: "序号",
      type: "index",
      align: "center",
      width: 50
    },
    {
      label: "下单时间",
      prop: "orderTime",
      align: "center"
    },
    {
      label: "订单编号",
      prop: "orderSn",
      align: "center"
    },
    {
      label: "订单金额",
      prop: "orderPrice",
      align: "center"
    },
    {
      label: "实付金额",
      prop: "payPrice",
      align: "center"
    }
  ]

  // 充值【藏地粮市】PLUS会员人数
  cPlusNumDialog = false
  cPlusNumQuery: any = {
    page: 1,
    limit: 10
  }
  cPlusNumTotal = 0
  cPlusNumList: any = []
  cPlusNumColumn: any = [
    {
      label: "序号",
      type: "index",
      align: "center",
      width: 50
    },
    {
      label: "用户昵称",
      prop: "userName",
      overHidden: true,
      align: "center",
    },
    {
      label: "用户电话",
      prop: "userPhone",
      align: "center",
    },
    {
      label: "PLUS会员费",
      prop: "memberPrice",
      align: "center",
    },
    {
      label: "购买时间",
      prop: "payTime",
      align: "center",
    },
  ]

  defaultYmd = ''

  pickerOptions = {
    disabledDate: (time: Date) => {
      return time.getTime() > new Date().getTime()||time.getTime() < new Date('2024/01/15 00:00:00').getTime();
    }
  }

  getInviteCNum () {
    return new Promise(resolve => {
      dataStatCInvitationToEnterApi(this.inviteCNumQuery).then(e => {
        this.inviteCNumList = e.data.list
        this.inviteCNumTotal = e.data.total
        this.inviteCNumSum = e.visitsSum || 0
        resolve(null)
      })
    })
  }


  async openInviteCNum (ymd: string, promoterId: string) {
    this.$set(this, "inviteCNumQuery", {
      page: 1,
      limit: 10,
      ymd,
      promoterId
    })
    await this.getInviteCNum()
    this.inviteCNumDialog = true
  }

  getInviteCNumDetail () {
    return new Promise(resolve => {
      dataStatCVisitsDetailApi(this.inviteCNumDetailQuery).then(e => {
        this.inviteCNumDetailList = e.list
        this.inviteCNumDetailTotal = e.total
        resolve(null)
      })
    })
  }

  async openInviteCNumDetail (openId: string) {
    this.$set(this, "inviteCNumDetailQuery", {
      page: 1,
      limit: 10,
      ymd: this.inviteCNumQuery.ymd,
      promoterId: this.inviteCNumQuery.promoterId,
      openId
    })
    await this.getInviteCNumDetail()
    this.inviteCNumDetailDialog = true
  }


  getCOrderNum () {
    return new Promise(resolve => {
      dataStatCGenerateOrdersApi(this.cOrderNumQuery).then(e => {

        this.cOrderNumPriceTotal = e.orderPriceSum || 0
        this.cOrderNumSum = e.ordersNoSum || 0
        this.cOrderNumPayPriceTotal = e.payPriceSum || 0

        this.cOrderNumList = e.data.list
        this.cOrderNumTotal = e.data.total

        resolve(null)
      })
    })
  }

  async openCOrderNum (ymd: string, promoterId: string) {
    this.$set(this, 'cOrderNumQuery', {
      page: 1,
      limit: 10,
      ymd,
      promoterId
    })
    await this.getCOrderNum()
    this.cOrderNumDialog = true
  }

  getCOrderNumDetail () {
    return new Promise(resolve => {
      dataStatCGenerateOrdersDetailApi(this.cOrderNumDetailQuery).then(e => {

        this.cOrderNumDetailPayPriceTotal = e.payPriceSum
        this.cOrderNumDetailPriceTotal = e.orderPriceSum

        this.cOrderNumDetailList = e.data.list;
        this.cOrderNumDetailTotal = e.data.total;

        resolve(null)
      })
    })
  }

  async openCOrderNumDetail (openId: string) {
    this.$set(this, 'cOrderNumDetailQuery', {
      page: 1,
      limit: 10,
      openId,
      ymd: this.cOrderNumQuery.ymd,
      promoterId: this.cOrderNumQuery.promoterId
    })
    await this.getCOrderNumDetail()
    this.cOrderNumDetailDialog = true
  }

  getCPlusNum () {
    return new Promise(resolve => {
      dataStatCRechargeMemberNumberApi(this.cPlusNumQuery).then(e => {

        this.cPlusNumTotal = e.total
        this.cPlusNumList = e.list

        resolve(null)
      })
    })
  }

  async openCPlusNum (ymd: string, promoterId: string) {
    this.$set(this, 'cPlusNumQuery', {
      page: 1,
      limit: 10,
      ymd,
      promoterId
    })
    await this.getCPlusNum()
    this.cPlusNumDialog = true
  }


  exportExcel () {
    const query: IStatisticsPromoterDataParam = deepCopy(this.queryParam)

    if (query.day && query.day.length) {
      query.addStartTime = query.day[0]
      query.addEndTime = query.day[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.day
    dataStatCQueryByExportApi(query).then(e => {
      exportFile(e, "推广数据统计.xlsx")
    })
  }

  resetSearch () {
    this.$set(this.queryParam, 'day', [this.defaultYmd, this.defaultYmd])
    this.getList()
  }

  getList () {
    this.tableLoading = false

    const query: IStatisticsPromoterDataParam = deepCopy(this.queryParam)

    if (query.day && query.day.length) {
      query.addStartTime = query.day[0]
      query.addEndTime = query.day[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.day

    dataStatCQueryByPageApi(query).then(e => {
      if (e) {
        this.tableData = e.data.list
        this.tableTotal = e.data.total

        this.inviteCPeopleNum = e.visitCount || 0
        this.cOrderPeopleNum = e.userOrderCount || 0
        this.cOrderNum = e.orderCount || 0
        this.cOrderPrice = e.orderPrice || 0
        this.cPlusNum = e.memberCount || 0

        this.tableLoading = false
      }
    })
  }


  created () {
    const date = new Date()
    const ymd = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    this.defaultYmd = ymd
    this.$set(this.queryParam, 'day', [ymd,ymd])
    this.getList()
  }
}
